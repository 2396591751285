import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let PlaygroundIconsPage = class PlaygroundIconsPage extends Vue {
    created() {
        const icons = require.context('@/assets/icons', true, /^.*\.svg$/);
        this.iconsList = icons.keys().map(iconName => iconName.replace('./', '').replace('.svg', ''));
    }
};
PlaygroundIconsPage = __decorate([
    Component({})
], PlaygroundIconsPage);
export default PlaygroundIconsPage;
